
<template>
  <div class="distribution-div">
            <div class="top-title">
               <div>服务对象名称:&nbsp;{{detailMessage.userName}}</div>
               <div>性别:&nbsp;{{detailMessage.sex == 0 ?'未知':detailMessage.sex == 1 ?'男':detailMessage.sex == 2 ?'女':''}}</div>
               <!-- <div>所属区域:&nbsp;{{'番禺区小谷围街道星汇社区'}}</div> -->
               <div>详细地址:&nbsp;{{detailMessage.addr}}</div>
               <!-- <div class="btn"><Button type="primary" @click="openEdit">修改</Button></div> -->
           </div>
      <div class="container">
          <!-- <div class="tree-left">
           <div class="tree-title">
               <Tabs value="name1">
                    <TabPane label="长者监测" name="name1">
                        <div style="font-size:16px;display:flex;padding:0 20px">长者名称：<span style="margin-left:auto">{{'金大明'}}</span></div>
                        <div style="font-size:16px;display:flex;padding:10px 20px">年龄：<span style="margin-left:auto">{{'79岁'}}</span></div>
                        <div style="font-size:16px;display:flex;padding:0 20px">性别：<span style="margin-left:auto">{{'男'}}</span></div>
                    </TabPane>
                    <TabPane label="设备信息" name="name2">
                        <div class="select-div">
                           <div :class="menuIndex==item.value ?'item item-active':'item'" v-for="(item,index) in searchList" :key="index" @click="changeIndex(item.value)">{{item.name}}</div>
                        </div>
                    </TabPane>
                </Tabs>
           </div>
          </div> -->
          <div class="table-right">
              <div class="table-top">
                  <Tabs value="name3">
                      <TabPane label="设备状态" name="name3">
                          <div v-if="detailMessage.deviceList && detailMessage.deviceList.length ==0" style="padding-left:20px">
                              暂无设备
                          </div>
                            <div class="table-card" v-else>
                              <Card class="cardsty" style="background: #2d8cf0" v-for="(item,index) in detailMessage.deviceList" :key="index">
                                <div>
                                    <h1>{{item.deviceName}}</h1>
                                    <p :style="item.runStatus!= '1'?'color:red':''">{{item.runStatus == '1' ?'正常' : item.runStatus == '2' ?'异常':''}}</p>
                                </div>
                                </Card>
                         </div>
                      </TabPane>
                      <TabPane label="家庭联系方式" name="name4">
                           <Table width="100%" height="750" stripe border :columns="familyColumns" :data="detailMessage.familyMemberList"></Table>
                      </TabPane>
                  </Tabs>
              </div>
              <!-- <div class="table-container">
                  <div class="title">设备操作记录</div>
                   <Table width="100%" height="320" stripe border :columns="talbeColumns" :data="tableData"></Table>
              </div> -->
          </div>
      </div>
       <!--修改服务对象-->
        <!-- <LiefengModal
            :value="addDistribution.status"
            @input="addDistributionStatusFn"
            title="修改服务对象"
            :fullscreen="true"
            class="distribution-div"
        >
            <template v-slot:contentarea>
            <AddDistribution></AddDistribution>
            </template>
            <template v-slot:toolsbar>
            <Button style="margin: 0 5px 0 13px" type="warning" @click="addDistribution.tip = true; addDistributionStatusFn(false);">取消</Button>
            <Button style="margin: 0 5px 0 13px" type="primary" @click="saveAddDistribute">保存</Button>
            </template>
        </LiefengModal> -->
  </div>
</template>

<script>
  import AddDistribution from './AddDistribution'
  import LiefengModal from "@/components/LiefengModal";
  export default {
      props: {
          detailMessage: {
            type: Object,
            default: () => {
                return {};
            }
        }
      },
       
    //import引入的组件需要注入到对象中才能使用
    components: {AddDistribution,LiefengModal},
    data() {
      //这里存放数据
      return {
             searchList:[
                 {name:'全部',value:0},
                 {name:'新世代网关',value:1},
                 {name:'紧急按钮',value:2},
                 {name:'门磁',value:3},
                 {name:'燃气报警器',value:4},
                 {name:'烟雾报警器',value:5},
                 {name:'水浸报警器',value:6},
                 {name:'摄像头',value:7},
             ],
             menuIndex:0,
             talbeColumns:[
            {
                title: "名称",
                key: "name",
                minWidth: 200,
                align: "center"
            },
            {
                title: "性别",
                key: "sex",
                minWidth: 200,
                align: "center"
            },
            {
                title: "年龄",
                key: "age",
                minWidth: 200,
                align: "center"
            },
            {
                title: "地址",
                key: "orgCode",
                minWidth: 200,
                align: "center"
            },
        ],
        //   修改服务对象
        addDistribution:{
            status:false,
            tip:false
        },
        familyColumns:[
            {
                title: "姓名",
                key: "userName",
                minWidth: 200,
                align: "center"
            },
            {
                title:'头像',
                minWidth:200,
                align:'center',
                render:(h,params)=>{
                return h('div',[
                    h('img',{
                      props:{

                      },
                      style:{
                        width:'30px',
                        height:'30px',
                        borderRadius:'50%',
                        textAlign:'center',
                        display:params.row.avatarUrl?'block' : 'none'
                      },
                      attrs:{
                        src:params.row.avatarUrl,
                      }
                    },

                    )
                ])
              }
            },
            {
                title: "在家庭角色",
                key: "relationship",
                minWidth: 200,
                align: "center"
            },
            {
                title: "手机号",
                key: "mobile",
                minWidth: 200,
                align: "center"
            },
            {
                title: "性别",
                key: "mobile",
                minWidth: 200,
                align: "center",
                render:(h,params)=>{
                    return h('div',{},params.row.sex == 0?'未知':params.row.sex == 1?'男':params.row.sex == 2 ?'女':'')
                }
            },
            
        ],
        familyData:[
        ]
      }          
    },
    //方法集合
    methods: {
        // 设备列表改变项
        changeIndex(index){
            this.menuIndex = index
        },
        // 点击修改按钮
        openEdit(){
            this.addDistribution.status = true;
        },
        // 关闭新增服务对象模态框事件
        addDistributionStatusFn(status) {
            if (!status && this.addDistribution.tip) {
                this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
                onOk: () => {
                    this.addDistribution.status = status;
                }
                });
            } else {
                this.addDistribution.status = status;
            }
        },
        // 点击新增服务对象保存按钮
        saveAddDistribute(){
            this.addDistribution.status =false
            this.addDistribution.tip = false
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {},
  }
</script>
<style lang='less' scoped>
  //编写css
  .distribution-div{
      width: 100%;
      overflow-y: hidden;
      .top-title{
          display: flex;
          justify-content: flex-start;
          font-size: 16px;
          margin-bottom: 5px;
          div{
              margin-right: 20px;
          }
          .btn{
              margin-left: auto;
              margin-right: 0;
          }
      }
      .container{
          width: 100%;
          display:flex; 
          justify-content: space-between;
          .tree-left{
            height: 750px;
            width: 15%;
            border: 1px solid rgba(000, 000, 000, 0.1);
            overflow-y: scroll;
            position: relative;
            .tree-title{
                .select-div{
                   .item{
                       width: 100%;
                       padding: 10px ;
                       border-top:1px solid rgba(000, 000, 000, 0.1);
                       cursor: pointer;
                       user-select: none;
                       border-radius: 5px;
                       &:nth-last-child(1){
                           border-bottom: 1px solid rgba(000, 000, 000, 0.1);
                       }
                       &-active{
                           background: #2d8cf0;
                           color: #ffffff;
                       }
                   }
                }
            }
        } 
        .table-right{
            // margin-left: 20px;
            // height: 750px;
            width:100%;
            overflow-y: scroll;
            border: 1px solid rgba(000, 000, 000, 0.1);
           /deep/ .ivu-table-wrapper-with-border{
                height: 100%;
            }
           /deep/ .ivu-table-tip{
                height: 100%;
                table{
                    height: 100%;
                }
           }
        }
        .table-top{
            height: 50%;
            margin-bottom: 20px;
            .table-card{
                margin: 0 20px;
                width: 100%;
                // height: 690px;
                overflow-y: scroll;
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                .cardsty {
                margin: 10px;
                max-width: 250px;
                width: 22%;
                background: #2db7f5;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                    p {
                    font-size: 16px;
                    margin-top: 20px;
                    }
                }
            }
        }
        .table-container{
            // height: 50%;
            overflow-y: scroll;
            .title{
                position: relative;
                padding-left: 20px;
                margin-bottom:10px;
                &::after{
                    position: absolute;
                    content:'';
                    top: 0;
                    left: 0;
                    width: 5px;
                    height: 20px;
                    background-color: #2db7f5;
                }
            }
        }       
      }
  }
  .distribution-div{
     /deep/.ivu-modal-close {
            display: none;
    } 
   
  }
</style>
