<template>
  <div>
      <Form class="form-div" label-position="left" :label-width="100" style="width:50%">
        <div class="equiment-title">基本信息</div>
        <FormItem>
            <span slot="label" class="validate">姓名:</span>
            <Input
            style="width:80%;"
            placeholder="限15个字"
            v-model.trim="formData.userName"
            :maxlength="15"
            />
        </FormItem>
         <FormItem>
            <span slot="label">服务等级:</span>
            <RadioGroup v-model="formData.serviceGrade">
            <Radio label="1">
                1级
            </Radio>
            <Radio label="2">
                2级
            </Radio>
            <Radio label="3">
                3级
            </Radio>
            <Radio label="4">
                4级
            </Radio>
            <Radio label="5">
                5级
            </Radio>
            </RadioGroup>
        </FormItem>
        <FormItem>
            <span slot="label">服务类型:</span>
             <RadioGroup v-model="formData.serviceType">
            <Radio label="1">
                扶持对象
            </Radio>
            <Radio label="2">
                自费对象
            </Radio>
            </RadioGroup>
        </FormItem>
        <FormItem>
            <span slot="label">性别:</span>
            <RadioGroup v-model="formData.sex">
            <Radio label="1">
                男
            </Radio>
            <Radio label="2">
                女
            </Radio>
            </RadioGroup>
        </FormItem>
        <FormItem>
            <span slot="label" class="validate">身份证:</span>
            <Input
            style="width:80%;"
            placeholder="限18个字"
            v-model.trim="formData.idNum"
            :maxlength="18"
            />
        </FormItem>
        <FormItem> 
            <span slot="label">手机号:</span>
            <Input
            style="width:80%;"
            placeholder="限15个字"
            v-model.trim="formData.mobile"
            :maxlength="15"
            />
        </FormItem>
        <div class="equiment-title">家庭信息</div>
        <FormItem>
            <span slot="label" class="validate">所属社区:</span>
            <div class="cascader-div">
            <Select
                v-model="cityOper.city"
                transfer
                style="width: 20%;margin-right:10px"
                :max-tag-count="1"
                placeholder="请选择市"
                filterable
                @on-change="changeCity"
            >
                <Option
                :value="item.value"
                v-for="(item, index) in cityList"
                :key="index"
                >{{ item.label }}</Option>
            </Select>
            <Select
                v-model="cityOper.region"
                transfer
                style="width: 20%;margin-right:10px"
                :max-tag-count="1"
                placeholder="请选择区"
                :disabled="regionDisabled"
                filterable
                @on-change="changeRegion"
            >
                <Option
                :value="item.value"
                v-for="(item, index) in regionList"
                :key="index"
                >{{ item.label }}</Option>
            </Select>
            <Select
                v-model="cityOper.stree"
                transfer
                style="width: 20%;margin-right:10px"
                :max-tag-count="1"
                placeholder="请选择街道/乡镇"
                :disabled="streeDisabled"
                filterable
                @on-change="changeStree"
            >
                <Option
                :value="item.value"
                v-for="(item, index) in streeList"
                :key="index"
                >{{ item.label }}</Option>
            </Select>
            <Select
                v-model="cityOper.project"
                transfer
                style="width: 20%"
                :max-tag-count="1"
                placeholder="请选择社区"
                :disabled="projectDisabled"
                filterable
            >
                <Option
                :value="item.value"
                v-for="(item, index) in projectList"
                :key="index"
                >{{ item.label }}</Option>
            </Select>
            </div>
      </FormItem>
      <FormItem>
          <span slot="label" class="validate">详细地址:</span>
            <Input
            style="width:80%;"
            placeholder="限15个字"
            v-model.trim="formData.address"
            :maxlength="15"
            />
      </FormItem>
      <FormItem>
          <span slot="label" >经度:</span>
            <Input
            style="width:80%;"
            placeholder="限15个字"
            v-model.trim="formData.longitude"
            :maxlength="15"
            />
      </FormItem>
      <FormItem>
          <span slot="label" >纬度:</span>
            <Input
            style="width:80%;"
            placeholder="限15个字"
            v-model.trim="formData.latitude"
            :maxlength="15"
            />
      </FormItem>
     
    </Form>
  </div>
</template>

<script>
  export default {
    props:{
        cityOper: {
            type: Object,
            default: () => {
                return {};
            }
        },
        formData:{
            type:Object,
            default:() =>{
                return {}
            }
        },
        isEdit: {
            type: Boolean,
            default: () => {
                return false;
            }
        },
        memberFromScope: {
            type: String,
            default: () => {
                return "";
            }
        },
    },
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
      //这里存放数据
      return {
           //   市
      city: "",
      cityList: [], //存放城市列表
      //  区
      region: "",
      regionDisabled: true,
      regionList: [], // 存放区的列表
      //   街道
      stree: "",
      streeDisabled: true,
      streeList: [], //存放街道的列表

      // 区
      project: "",
      projectDisabled: true,
      projectList: [], //存放区的列表
      problem: {},
      chooseLocal: [],
      regionCode: "",
      cityCode: "",

      List: []
      }
    },
    //方法集合
    methods: {
        // 选择城市回调事件
    changeCity(code) {
      this.regionDisabled = false;
      let arrCode = code.split("-");
      this.getPulishData(arrCode[arrCode.length - 1], "regionList", "region");
      this.cityCode = arrCode[arrCode.length - 1];
    },
    // 选择区的回调事件
    changeRegion(code) {
      let arrCode = code.split("-");
      if (this.regionCode == "") {
        this.streeDisabled = false;
        this.getPulishData(arrCode[arrCode.length - 1], "streeList", "stree");
      } else {
        if (this.regionCode == arrCode[arrCode.length - 1]) {
          this.streeDisabled = false;
          this.getPulishData(arrCode[arrCode.length - 1], "streeList", "stree");
        } else {
          this.streeList = [];
          this.projectList = [];
          this.stree = [];
          this.project = [];
          this.projectDisabled = true;
          this.getPulishData(arrCode[arrCode.length - 1], "streeList", "stree");
        }
      }
      this.regionCode = arrCode[arrCode.length - 1];
    },
    // 选择街道的回调事件
    changeStree(code) {
      let arrCode = code.split("-");
      this.projectDisabled = false;
      this.getPulishData(arrCode[arrCode.length - 1], "projectList", "project");
    },
// 获取发布范围列表
    getPulishData(code, list, model) {
      if (!this.isEdit) {  
        this.$get("/datamsg/api/pc/staff/selectCascadeDataScope", {
          orgCode: code,
          oemCode: parent.vue.oemInfo.oemCode,
          orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
          custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId
        }).then(res => {
          if (res.code == 200) {
            this[list] = res.dataList.map(item => {
              return {
                value: item.orgPath,
                label: item.orgName,
                orgLevel: item.orgLevel,
                code: item.orgCode
              };
            });
            if (res.dataList.length == "1") {
              this[model] = [res.dataList[0].orgPath];
            }
          }
        });
      } else {
        this.$get("/datamsg/api/pc/staff/selectCascadeDataScope", {
          orgCode: code,
          oemCode: parent.vue.oemInfo.oemCode,
          orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
          custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId
        }).then(res => {
          if (res.code == 200) {
            this[list] = res.dataList.map(item => {
              return {
                value: item.orgPath,
                label: item.orgName,
                orgLevel: item.orgLevel,
                code: item.orgCode
              };
            });
            if (res.dataList.length == "1") {
              this[model] = [res.dataList[0].orgPath];
              if (model == "city") {
                this.cityOper.city = this[model][0];
                this.changeCity(this.cityOper.city);
              } else if (model == "region") {
                this.cityOper.region = this[model][0];
                this.changeRegion(this.cityOper.region);
              } else if (model == "stree") {
                this.cityOper.stree = this[model][0];
                this.changeStree(this.cityOper.stree);
              }
            }
          }
        });
      }
    },   
    },

    //生命周期 - 创建完成（可以访问当前this实例）
    async created() {
        await this.getPulishData("44", "cityList", "city");
    },
    watch:{
        memberFromScope: {
            handler(val, newVal) {
                if (val) {
                let data = val.split("-");
                switch (data.length) {
                    case 2:
                    this.cityOper.city = data[0] + "-" + data[1];
                    this.changeCity(this.cityOper.city);
                    break;
                    case 3:
                    this.cityOper.city = data[0] + "-" + data[1];
                    this.cityOper.region = data[0] + "-" + data[1] + "-" + data[2];
                    this.changeCity(this.cityOper.city);
                    this.changeRegion(this.cityOper.region);
                    break;
                    case 4:
                    this.cityOper.city = data[0] + "-" + data[1];
                    this.cityOper.region = data[0] + "-" + data[1] + "-" + data[2];
                    this.cityOper.stree =
                        data[0] + "-" + data[1] + "-" + data[2] + "-" + data[3];
                    this.changeCity(this.cityOper.city);
                    this.changeRegion(this.cityOper.region);
                    this.changeStree(this.cityOper.stree);
                    break;
                    case 5:
                    this.cityOper.city = data[0] + "-" + data[1];
                    this.cityOper.region = data[0] + "-" + data[1] + "-" + data[2];
                    this.cityOper.stree =
                        data[0] + "-" + data[1] + "-" + data[2] + "-" + data[3];
                    this.cityOper.project =
                        data[0] +
                        "-" +
                        data[1] +
                        "-" +
                        data[2] +
                        "-" +
                        data[3] +
                        "-" +
                        data[4];
                    this.changeCity(this.cityOper.city);
                    this.changeRegion(this.cityOper.region);
                    this.changeStree(this.cityOper.stree);
                    break;
                }
                }
            }
            }
    }
  }
</script>
<style lang='less' scoped>
  //编写css
  .form-div{
      margin: 20px;
  }
  .equiment-title{
    position: relative;
    padding-left: 20px;
    margin:10px 0;
    &::after{
        position: absolute;
        content:'';
        top: 0;
        left: 0;
        width: 5px;
        height: 20px;
        background-color: #2db7f5;
    }
}
</style>